import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { Form, Input, Message, Radio } from 'semantic-ui-react'

import { NEW_RECORD_FORM_ID } from 'assets/constants/forms'
import { paths } from 'assets/constants/navigation'
import { eventCodes } from 'assets/constants/notifications'
import { dateToStr } from 'assets/utils/common'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import useNotifications from 'hooks/useNotifications'
import accountsService from 'services/accounts'
import businessService from 'services/business'
import recordsService from 'services/records'
import styles from './RecordForm.module.scss'


const RecordForm = (props) => {
    const { className, errors, initialValues, modality = 1, onSubmit, refreshCount } = props

    const { userGroups, user } = useAuth()
    const { closeDialog, openErrorDialog } = useDialog()
    const { receivedEvent, eventNumber } = useNotifications()
    const [data, setData] = useState({
        entry_date: initialValues?.entry_date || dateToStr(new Date(), true),
        type: initialValues?.type || null,
        partner: initialValues?.partner || null,
        insured: initialValues?.insured || (modality === 1 ? '' : null),
        insurance: initialValues?.insurance || null,
        policy_number: initialValues?.policy_number || '',
        claim_reference: initialValues?.claim_reference || '',
        opposite: initialValues?.opposite || '',
        claimed_amount: initialValues?.claimed_amount || null,
        description: initialValues?.description || '',
        invoice_issued: initialValues?.invoice_issued || false,
        invoice_number: initialValues?.invoice_number || '',
        invoice_amount: initialValues?.invoice_amount || null,
        closed: initialValues?.closed || false,
        created_by: initialValues?.created_by || null,
    })
    const [isClosed, setClosed] = useState(initialValues?.closed ? 'true' : 'false')
    const [brokerUsersMap, setBrokerUsersMap] = useState(null)
    const [brokerUsers, setBrokerUsers] = useState(null)
    const [partners, setPartners] = useState(null)
    const [insureds, setInsureds] = useState(null)
    const [insurances, setInsurances] = useState(null)
    const [recordTypes, setRecordTypes] = useState(null)
    const [requestedDocumentsMap, setRequestedDocumentsMap] = useState(null)
    const navigate = useNavigate()
    const itemsLiterals = {
        USERS: 'los usuarios',
        PARTNERS: 'los asociados',
        INSUREDS: 'los asegurados',
        INSURANCES: 'las compañías',
        RECORD_TYPES: 'los tipos de expediente',
    }

    const allLoaded = !!brokerUsers && !!partners && !!insureds && !!insurances && !!recordTypes
    const isUserConsultant = userGroups?.consultants || userGroups?.consultant_interns

    const handleChange = (event, result, isCheckbox) => {
        const { name, value, checked } = result || event.target
        setData({
            ...data,
            [name]: isCheckbox ? checked : (value || '')
        })
    }

    const handleError = (error, itemLiteral) => {
        let errorMessage = error?.response?.data?.error

        if (!errorMessage) {
            errorMessage = `Ha tenido lugar un error al cargar ${itemLiteral}.
                            Por favor, inténtalo de nuevo más tarde o
                            contacta con el administrador de la plataforma.`
        }

        openErrorDialog({
            title: 'Error al cargar',
            content: errorMessage,
            size: 'tiny',
            onConfirm: () => navigate(paths.RECORDS),
        })
    }

    const storeOptions = (data, setter) => {
        const options = data.map((elem) => ({
            key: elem.id,
            value: elem.id,
            text: `${elem.name}${elem.tax_id ? ` (${elem.tax_id})` : ''}`,
        }))
        setter(options)
    }

    const fetchBrokerUsers = () => {
        accountsService.getBrokerUsers()
            .then((res) => {
                const options = []
                const usersMap = {}

                res.data.forEach((elem) => {
                    options.push({
                        key: elem.id,
                        value: elem.id,
                        text: `${elem.first_name} ${elem.last_name} (${elem.partner.name})`
                    })
                    usersMap[elem.id] = elem
                })

                setBrokerUsers(options)
                setBrokerUsersMap(usersMap)
            })
            .catch((error) => handleError(error, itemsLiterals.USERS))
    }

    const fetchPartners = () => {
        let fetchMethod = (modality === 1)
                            ? businessService.getPartnersCatalogue
                            : businessService.getFilterPartnersCatalogue

        fetchMethod()
            .then((res) => storeOptions(res.data, setPartners))
            .catch((error) => handleError(error, itemsLiterals.PARTNERS))
    }

    const fetchInsureds = () => {
        let fetchMethod = (modality === 1)
                            ? businessService.getInsuredsCatalogue
                            : businessService.getFilterInsuredsCatalogue

        fetchMethod()
            .then((res) => storeOptions(res.data, setInsureds))
            .catch((error) => handleError(error, itemsLiterals.INSUREDS))
    }

    const fetchInsurances = () => {
        let fetchMethod = (modality === 1)
                            ? businessService.getInsurancesCatalogue
                            : businessService.getFilterInsurancesCatalogue

        fetchMethod()
            .then((res) => storeOptions(res.data, setInsurances))
            .catch((error) => handleError(error, itemsLiterals.INSURANCES))
    }

    const fetchRecordTypes = () => {
        recordsService.getRecordTypesCatalogue()
            .then((res) => storeOptions(res.data, setRecordTypes))
            .catch((error) => handleError(error, itemsLiterals.RECORD_TYPES))
    }

    useEffect(() => {
        if (!userGroups) {
            return
        }

        if (modality === 2) {
            fetchInsureds()
        } else {
            setInsureds([])
        }

        if (isUserConsultant) {
            fetchPartners()

            if (modality === 1) {
                fetchBrokerUsers()
            } else {
                setBrokerUsers([])
            }
        } else {
            setPartners([])
            setBrokerUsers([])
            setData({
                ...data,
                partner: user.partner.id,
            })
        }
        
        fetchInsurances()
        fetchRecordTypes()
    }, [userGroups])

    useEffect(() => {
        const relevantUserEvents = [eventCodes.ACCESS_REQUEST_APPROVED, eventCodes.USER_UPDATED, eventCodes.USER_DELETED]
        const relevantPartnerEvents = [eventCodes.PARTNER_CREATED, eventCodes.PARTNER_DELETED, eventCodes.PARTNER_UPDATED]
        const relevantInsuredEvents = [eventCodes.INSURED_CREATED, eventCodes.INSURED_DELETED, eventCodes.INSURED_UPDATED]
        const relevantInsurancesEvents = [eventCodes.INSURANCE_CREATED, eventCodes.INSURANCE_DELETED, eventCodes.INSURANCE_UPDATED]
        const relevantRecordTypeEvents = [eventCodes.RECORD_TYPE_CREATED, eventCodes.RECORD_TYPE_DELETED, eventCodes.RECORD_TYPE_UPDATED]

        if (relevantUserEvents.includes(receivedEvent?.code) && isUserConsultant && (modality === 1)) {
            fetchBrokerUsers()
        }
        if (relevantPartnerEvents.includes(receivedEvent?.code) && isUserConsultant) {
            fetchPartners()
        }
        if (relevantInsuredEvents.includes(receivedEvent?.code) && (modality === 2)) {
            fetchInsureds()
        }
        if (relevantInsurancesEvents.includes(receivedEvent?.code)) {
            fetchInsurances()
        }
        if (relevantRecordTypeEvents.includes(receivedEvent?.code)) {
            fetchRecordTypes()
        }
    }, [receivedEvent?.code, eventNumber])

    useEffect(() => {
        if (data?.invoice_issued === false) {
            setData({
                ...data,
                invoice_number: '',
                invoice_amount: null,
            })
        }
    }, [data?.invoice_issued])

    useEffect(() => {
        const sanitizedValues = {}

        if (data?.type === '') {
            sanitizedValues.type = null
        }

        if (data?.created_by === '') {
            sanitizedValues.created_by = null
        }

        if (data?.partner === '') {
            sanitizedValues.partner = null
        }

        if (data?.insured === '' && (modality === 2)) {
            sanitizedValues.insured = null
        }

        if (data?.insurance === '') {
            sanitizedValues.insurance = null
        }

        if (data?.claimed_amount === '') {
            sanitizedValues.claimed_amount = null
        }

        if (data?.invoice_amount === '') {
            sanitizedValues.invoice_amount = null
        }

        setData({
            ...data,
            ...sanitizedValues,
        })
    }, [data?.type, data?.created_by, data?.partner, data?.insured, data?.insurance, data?.claimed_amount, data?.invoice_amount])

    useEffect(() => {
        const userId = data?.created_by

        if (!userId) {
            return
        }

        const partner = brokerUsersMap[userId]?.partner?.id

        if (partner) {
            setData({
                ...data,
                partner
            })
        }
    }, [data?.created_by])

    useEffect(() => {
        const partnerId = data?.partner

        if (!partnerId || !data?.created_by) {
            return
        }

        const selectedAuthor = brokerUsersMap[data?.created_by]

        if (selectedAuthor?.partner?.id !== partnerId) {
            setData({
                ...data,
                created_by: null,
            })
        }
    }, [data?.partner])

    useEffect(() => {
        setData({
            ...data,
            closed: isClosed === 'true'
        })
    }, [isClosed])

    useEffect(() => {
        // We only accept changing the insured from outside
        if (modality === 1) {
            setData({
                ...data,
                insured: initialValues?.insured || '',
            })
        }
    }, [initialValues])

    useEffect(() => {
        if (refreshCount && (modality === 2)) {
            setData({
                entry_date: initialValues?.entry_date || dateToStr(new Date(), true),
                type: initialValues?.type || null,
                partner: initialValues?.partner || null,
                insured: initialValues?.insured || null,
                insurance: initialValues?.insurance || null,
                policy_number: initialValues?.policy_number || '',
                claim_reference: initialValues?.claim_reference || '',
                opposite: initialValues?.opposite || '',
                claimed_amount: initialValues?.claimed_amount || null,
                description: initialValues?.description || '',
                invoice_issued: initialValues?.invoice_issued || false,
                invoice_number: initialValues?.invoice_number || '',
                invoice_amount: initialValues?.invoice_amount || null,
                closed: initialValues?.closed || false,
            })
        }
    }, [refreshCount])

    return (
        <>
            <Form
                id={NEW_RECORD_FORM_ID}
                className={`${styles.RecordForm}${className ? ` ${className}` : ''}`}
                onSubmit={() => onSubmit(data)}
                error={!!errors}
            >
                <Form.Group widths='equal'>
                    <Form.Input
                        required={isUserConsultant}
                        type='date'
                        label='Fecha de alta'
                        placeholder='Fecha de alta'
                        name='entry_date'
                        disabled={!allLoaded}
                        readOnly={userGroups?.brokers}
                        value={data.entry_date || ''}
                        onChange={handleChange}
                    />
                    {
                        (isUserConsultant && (modality === 1)) &&
                        <Form.Select
                            clearable
                            search
                            disabled={!allLoaded}
                            loading={!brokerUsers}
                            options={brokerUsers || []}
                            error={!!errors?.created_by}
                            label='Asignar alta a'
                            placeholder='Seleccionar usuario'
                            name='created_by'
                            value={data.created_by || ''}
                            onChange={handleChange}
                        />
                    }
                    <Form.Select
                        required
                        clearable
                        search
                        disabled={!allLoaded}
                        loading={!recordTypes}
                        options={recordTypes || []}
                        error={!!errors?.type}
                        label='Tipo de expediente'
                        placeholder='Seleccionar tipo'
                        name='type'
                        value={data.type || ''}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    {
                        isUserConsultant &&
                        <Form.Select
                            required
                            clearable
                            search
                            disabled={!allLoaded}
                            loading={!partners}
                            options={partners || []}
                            error={!!errors?.partner}
                            label='Asociado'
                            placeholder='Seleccionar asociado'
                            name='partner'
                            value={data.partner || ''}
                            onChange={handleChange}
                        />
                    }
                    <Form.Select
                        clearable
                        search
                        disabled={!allLoaded}
                        loading={!insurances}
                        options={insurances || []}
                        error={!!errors?.insurance}
                        label='Compañía'
                        placeholder='Seleccionar compañía'
                        name='insurance'
                        value={data.insurance || ''}
                        onChange={handleChange}
                    />
                    {
                        (modality === 1) &&
                        <Form.Input
                            disabled={!allLoaded}
                            error={!!errors?.insured}
                            label='NIF/CIF del asegurado/tomador'
                            placeholder='NIF/CIF del asegurado/tomador'
                            name='insured'
                            value={data.insured || ''}
                            onChange={handleChange}
                        />
                    }
                    {
                        (modality === 2) &&
                        <Form.Select
                            clearable
                            search
                            disabled={!allLoaded}
                            loading={!insureds}
                            options={insureds || []}
                            error={!!errors?.insured}
                            label='Asegurado/tomador'
                            placeholder='Seleccionar asegurado/tomador'
                            name='insured'
                            value={data.insured || ''}
                            onChange={handleChange}
                        />
                    }
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input
                        label='Número de póliza'
                        placeholder='Número de póliza'
                        name='policy_number'
                        disabled={!allLoaded}
                        value={data.policy_number || ''}
                        onChange={handleChange}
                    />
                    <Form.Input
                        label='Referencia siniestro'
                        placeholder='Referencia siniestro'
                        name='claim_reference'
                        disabled={!allLoaded}
                        value={data.claim_reference || ''}
                        onChange={handleChange}
                    />
                    <Form.Input
                        label='Contrario'
                        placeholder='Contrario'
                        name='opposite'
                        disabled={!allLoaded}
                        value={data.opposite || ''}
                        onChange={handleChange}
                    />
                    <Form.Field disabled={!allLoaded}>
                        <label>Cuantía reclamada</label>
                        <NumberFormat
                            customInput={Input}
                            disabled={!allLoaded}
                            placeholder='Cuantía reclamada'
                            name='claimed_amount'
                            label='€'
                            labelPosition='right'
                            value={data.claimed_amount || null}
                            onValueChange={(newValue) => {
                                setData({
                                    ...data,
                                    claimed_amount: newValue.floatValue || null
                                })
                            }}
                            thousandSeparator='.'
                            decimalSeparator=','
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </Form.Field>
                </Form.Group>
                {
                    (modality === 1) &&
                    <Form.TextArea
                        required
                        label='Descripción del expediente'
                        placeholder='Descripción del expediente'
                        name='description'
                        disabled={!allLoaded}
                        error={!!errors?.description}
                        value={data.description || ''}
                        onChange={handleChange}
                    />
                }
                {
                    isUserConsultant &&
                    <>
                        <Form.Group>
                            <Form.Checkbox
                                slider
                                width={3}
                                disabled={!allLoaded}
                                className={`${styles.Checkbox} ${styles.InvoiceField}`}
                                name='invoice_issued'
                                checked={data.invoice_issued || false}
                                label={<label>Factura emitida</label>}
                                onChange={(e, r) => handleChange(e, r, true)}
                            />
                            {
                                data.invoice_issued &&
                                <>
                                    <Form.Input
                                        width={8}
                                        label='Número de factura'
                                        placeholder='Número de factura'
                                        name='invoice_number'
                                        className={styles.InvoiceField}
                                        disabled={!allLoaded}
                                        value={data.invoice_number || ''}
                                        onChange={handleChange}
                                    />
                                    <Form.Field width={5}>
                                        <label>Importe de factura</label>
                                        <NumberFormat
                                            customInput={Input}
                                            disabled={!allLoaded}
                                            className={styles.InvoiceField}
                                            placeholder='Importe de factura'
                                            name='invoice_amount'
                                            label='€'
                                            labelPosition='right'
                                            value={data.invoice_amount || null}
                                            onValueChange={(newValue) => {
                                                setData({
                                                    ...data,
                                                    invoice_amount: newValue.floatValue || null
                                                })
                                            }}
                                            thousandSeparator='.'
                                            decimalSeparator=','
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </Form.Field>
                                </>
                            }
                        </Form.Group>
                        <Form.Group className={styles.RecordStatus}>
                            <Form.Field disabled={!allLoaded}>
                                <label>Estado del expediente</label>
                                <Radio
                                    label='Abierto'
                                    name='closed'
                                    value='false'
                                    checked={data.closed === false}
                                    onChange={(e, { value }) => setClosed(value)}
                                />
                            </Form.Field>
                            <Form.Field disabled={!allLoaded}>
                                <label>&nbsp;</label>
                                <Radio
                                    disabled={!allLoaded}
                                    label='Cerrado'
                                    name='closed'
                                    value='true'
                                    checked={data.closed === true}
                                    onChange={(e, { value }) => setClosed(value)}
                                />
                            </Form.Field>
                        </Form.Group>
                    </>
                }
                {
                    errors &&
                    <Message error>
                        <Message.List>
                        {
                            Object.entries(errors).map(([key, value], index) => (
                                <Message.Item key={index}>
                                    {Array.isArray(value) ? value[0] : value}
                                </Message.Item>
                            ))
                        }
                        </Message.List>
                    </Message>
                }
            </Form>
        </>
    )
}


export default RecordForm